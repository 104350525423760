.Goals{
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size: 4.3em;
    letter-spacing: -4px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    overflow-y: auto;
}

.addGoal{
    width: fit-content;
    align-self: center;
    border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    align-self: center;
    margin-bottom: 15px;
    
        
}
.deleteBtn{
    width: fit-content;
    align-self: center;
    border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    align-self: center;
    margin-bottom: 15px;
    
        
}
.addMinutes{
    width: fit-content;
    align-self: center;
    border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    align-self: center;
    margin-bottom: 15px; 
}

.newGoal{
    
    align-self: center;
    font-family: inherit;
    width: 25%;
    height: 15px;
    border: 0;
    border-bottom: 2px solid rgb(151, 149, 149);
    border-left: 2px solid rgb(151, 149, 149);
    outline: 0;
    font-size: 1.3rem;
    color: rgb(223, 223, 223);
    padding: 10px 0;
    background: transparent;
    transition: border-color 0.2s;
    margin-bottom: 10px;
  
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;


}
.timeSpent {
    align-self: center;
    font-family: inherit;
    width: 25%;
    height: 15px;
    border: 0;
    outline: 0;
    font-size: 1.3rem;
    color: rgb(255, 249, 249);
    padding: 10px 0;
    background: transparent;
    transition: border-color 0.2s;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(151, 149, 149);
}
.goalsList{
    width: 50%;
    align-self: center;
    text-align: center;
    font-family: inherit; 
    font-size: 2rem;
    color: white;
    
    
}
/* .signOutButton{
    position: fixed;
    top: 0;
    left: 90%;
    margin-top: 5px;
    margin-left: 15px;
} */

.listOfGoals{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow-y: auto;
}

.bar{
    width: 200px;
    height: 25px;
    align-self: center;
    background-color: white;
    position: relative;
}
.progress{
    position: absolute;
    height: 25px;
    width: 50%;
    background-color: aqua;
}
