.background{
  background-color: #f0f0f0;
  background-size: 100%;
  height: 100vh;
  /* background-image: linear-gradient( to bottom right,#ED872D,#512889); */
  /* background-image: linear-gradient(to bottom right, rgb(87, 241, 234), yellow) */
  background: linear-gradient(to right, rgba(0,0,0,0), teal), linear-gradient(to right, rgba(255,0,100,.3), rgba(255,100,127,.2)), linear-gradient(to top right, yellow, rgba(0,0,0,0)), radial-gradient(closest-corner at 20% 80%, yellow, red);
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  position: relative;
  margin-bottom: 25px;
  padding-left: 20px;
  color: rgb(243, 243, 243);
  font-family: Papyrus, fantasy;
  font-size: 40px;
  background-size: 100%;
  height: 50px;
  /* background-color: #eee; */
  
background-color: linear-gradient(to right, rgba(0,0,0,0), teal), linear-gradient(to right, rgba(255,0,100,.3), rgba(255,100,127,.2)), linear-gradient(to top right, yellow, rgba(0,0,0,0)), radial-gradient(closest-corner at 20% 80%, yellow, red);
background-size: 75px 100px;
}

.signOutButton{
  border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
}



.email {
  
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
}



.email {
  
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
}



.email {
  
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
}



.email {
  
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
}



.email {
  
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  background-color: #eee;
  border: none;
  display: inline-block;
  padding: 10px 15px;
  width: 75px;
  padding-right: 25px;
  margin-bottom:2px;
  border-bottom: 2px solid black;
}
.inputEmail {
  align-self: center;
  font-family: inherit;
  width: 20%;
  height: 15px;
  border: 0;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  outline: 0;
  font-size: 1.3rem;
  color: white;
  padding: 10px 0;
  background: transparent;
  transition: border-color 0.2s;
  margin-bottom: 10px;
}
.inputPassword{
  margin-bottom: 25px;
   
  align-self: center;
  font-family: inherit;
  width: 20%;
  height: 15px;
  border: 0;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  outline: 0;
  font-size: 1.3rem;
  color: white;
  padding: 10px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.password{
  font-size: 16.5px;
  align-items: center;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
  background-color: #eee;
  border: none;
  display: inline-block;
  padding: 10px 15px;
  width: 75px;
  padding-right: 25px;
}

.signInButton {
  border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
  transition: transform 80ms ease-in;
  align-self: center;
  margin-bottom: 15px;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.signUpButton {
  border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
  transition: transform 80ms ease-in;
  align-self: center;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.signOutButton {
  border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
  transition: transform 80ms ease-in;
  
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.Auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  
}


